import TrkdSigDevCard from "~/components/TrkdSigDevCard.vue";
import useBaseUtils from "~/functions/useBaseUtils";
export default defineComponent({
  components: {
    TrkdSigDevCard: TrkdSigDevCard
  },
  props: {
    compress: {
      type: Boolean,
      "default": true
    },
    title: {
      type: String,
      "default": "Market"
    },
    gridtype: {
      type: String,
      "default": "market"
    },
    sigDevs: {
      type: Array,
      "default": function _default() {
        return [];
      }
    },
    error: {
      type: Object,
      "default": null
    },
    loading: {
      type: Boolean,
      "default": true
    }
  },
  setup: function setup() {
    var _useBaseUtils = useBaseUtils(),
      I18nFn = _useBaseUtils.I18nFn,
      sectionWrapperStyle = _useBaseUtils.sectionWrapperStyle;
    return {
      I18nFn: I18nFn,
      sectionWrapperStyle: sectionWrapperStyle
    };
  }
});