import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _typeof(o) {
  "@babel/helpers - typeof";

  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) {
    return typeof o;
  } : function (o) {
    return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o;
  }, _typeof(o);
}
function ownKeys(e, r) {
  var t = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) {
      return Object.getOwnPropertyDescriptor(e, r).enumerable;
    })), t.push.apply(t, o);
  }
  return t;
}
function _objectSpread(e) {
  for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {
      _defineProperty(e, r, t[r]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {
      Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));
    });
  }
  return e;
}
function _defineProperty(e, r, t) {
  return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[r] = t, e;
}
function _toPropertyKey(t) {
  var i = _toPrimitive(t, "string");
  return "symbol" == _typeof(i) ? i : i + "";
}
function _toPrimitive(t, r) {
  if ("object" != _typeof(t) || !t) return t;
  var e = t[Symbol.toPrimitive];
  if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != _typeof(i)) return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return ("string" === r ? String : Number)(t);
}
import useBaseUtils from "~/functions/useBaseUtils";
import useDateTimeFormatters from "~/functions/useDateTimeFormatters";
export default defineComponent({
  props: {
    dev: {
      type: Object,
      "default": function _default() {
        return {};
      }
    }
  },
  setup: function setup() {
    var _useBaseUtils = useBaseUtils(),
      dev = _useBaseUtils.dev,
      I18nFn = _useBaseUtils.I18nFn;
    var _useDateTimeFormatter = useDateTimeFormatters(),
      userEpochToOnlyTime = _useDateTimeFormatter.userEpochToOnlyTime,
      userEpochToLongDate = _useDateTimeFormatter.userEpochToLongDate;
    var formatDate = function formatDate(dateStr) {
      var dateObj = new Date(dateStr + "Z");
      return "".concat(userEpochToOnlyTime.value.format(dateObj), " | ").concat(userEpochToLongDate.value.format(dateObj));
    };
    return {
      development: dev,
      formatDate: formatDate,
      I18nFn: I18nFn
    };
  },
  data: function data() {
    return {
      show: false,
      isActive: false
    };
  },
  computed: {
    devId: function devId() {
      return this.dev.Xrefs ? this.dev.Xrefs.DevelopmentId : "";
    },
    mobile: function mobile() {
      return this.$vuetify.breakpoint.name === "xs";
    },
    splitDescription: function splitDescription() {
      var firstSplit = this.dev.Description.split("::");
      return firstSplit.length === 2 ? firstSplit : ["", ""];
    },
    devTitle: function devTitle() {
      return this.splitDescription[0];
    },
    description: function description() {
      var block = this.splitDescription[1];
      var spaceSplit = block.split(" ");
      var periodRegex = /\./;
      var periodInNumberRegex = /\d\.\d/;
      return spaceSplit.reduce(function (acc, word) {
        // does the word contain a period?
        var previousWord = acc.pop();
        if (periodRegex.test(word)) {
          // is the period surrounded by numbers?
          if (periodInNumberRegex.test(word)) {
            // its a number add to existing string
            previousWord = previousWord + " ".concat(word);
            acc.push(previousWord);
          } else {
            //  its a period
            var splitPeriod = word.split(".");
            if (splitPeriod[0].length > 0) {
              previousWord = previousWord + " ".concat(splitPeriod[0]);
              acc.push(previousWord);
            }
            if (splitPeriod[1].length > 0) {
              acc.push(splitPeriod[1]);
            }
          }
        } else {
          // this is another word, add to existing string
          previousWord = previousWord + " ".concat(word);
          acc.push(previousWord);
        }
        return acc;
      }, [""]);
    },
    sigDevFormat: function sigDevFormat() {
      return this.$store.state.config.sigDevFormat;
    }
  },
  methods: {
    showSigDev: function showSigDev() {
      var currentState = this.show;
      var currentRoute = this.$route.path;
      var currentQuery = this.$route.query;
      if (currentState) {
        this.show = false;
        var newQueryParams = _objectSpread({}, currentQuery);
        if (newQueryParams.sd) {
          delete newQueryParams.sd;
          this.$router.replace({
            path: currentRoute,
            query: newQueryParams
          });
        }
      } else {
        this.show = true;
        this.$router.replace({
          path: currentRoute,
          query: _objectSpread(_objectSpread({}, currentQuery), {
            sd: this.devId
          })
        });
      }
    },
    closeSigDev: function closeSigDev() {
      this.show = false;
      try {
        this.$vuetify.goTo("#sig-".concat(this.devId), {
          container: ".sigdevcontainer"
        });
      } catch (error) {
        console.error("Error .goto from sigDevCard: ", error);
      }
      var currentRoute = this.$route.path;
      var currentQuery = this.$route.query;
      var newQueryParams = _objectSpread({}, currentQuery);
      if (newQueryParams.sd) {
        delete newQueryParams.sd;
        this.$router.replace({
          path: currentRoute,
          query: newQueryParams
        });
      }
    },
    toProperCase: function toProperCase(str) {
      var lower = str.toLowerCase();
      return lower.charAt(0).toUpperCase() + lower.slice(1);
    }
  }
});